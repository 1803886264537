<template>
<div>
    <section class="bg-bdy">
        <div class="sticky-top">
            <div class="fixed-top"><!-- Start: menu phone -->
                <div class="home-search position-relative" v-if="$device.isMobile">
                    <div class="d-flex justify-content-evenly align-items-center w-100 bg-main"><!-- Start: tog -->
                        <div class="d-flex flex-column justify-content-center justify-content-sm-center h-100 pointer">
                            <div class="img-logo-sm px-1" @click="showmenugoc=!showmenugoc"><img class="w-100 h-100" :src="websiteConfig.iconicLogo"></div>
                        </div><!-- End: tog --><!-- Start: input -->
                        <div class="d-flex justify-content-center align-items-center me-2 w-50 h-50">
                            <div class="input-group d-flex flex-row search-cha"><span class="input-group-text text-white fs-12"><i class="fa fa-search px-2"></i></span><input type="checkbox" class="checkbox-hack"><input class="form-control home-search-input-group fs-12" type="text" placeholder="Tìm sản phẩm" style="border-right-color: rgba(206,212,218,0);border-left-color: rgba(206,212,218,0);background: rgb(243,245,246);" @update:model-value="inputtext52578ebc4e1f4b316401b4c33393e7642ba3b3ccupdatemodelvalue"></div>
                        </div><!-- End: input --><!-- Start: filter -->
                        <div class="d-flex justify-content-center align-items-center position-relative" @click="showFilter=!showFilter"><i class="fa fa-sliders pe-2 fs-27"></i></div><!-- End: filter --><!-- Start: giỏ hàng -->
                        <div class="d-flex justify-content-center align-items-center position-relative"><nuxt-link-locale class="a-decoration-none" to="/gio-hang"><i class="fa fa-shopping-cart pe-2 fs-27"></i></nuxt-link-locale><span class="cart-count-mobile">0</span></div><!-- End: giỏ hàng -->
                    </div>
                </div><!-- End: menu phone --><!-- Start: pc -->
                <header class="position-relative bg-home" v-if="$device.isDesktop"><!-- Start: menu pc -->
                    <div class="container">
                        <div class="row g-0 d-flex w-100"><!-- Start: logo -->
                            <div class="col-md-2 col-lg-2 d-flex justify-content-center align-items-center">
                                <div class="d-flex d-xxl-flex flex-row justify-content-between align-items-center pointer h-100 w-100 pb-2"><nuxt-link-locale class="h-100 text-decoration-none" to="/">
                                        <div class="d-flex flex-row align-items-center h-100 pointer"><img class="img-fluid w-100 logo-img" alt="logo" :src="websiteConfig.logo"></div>
                                    </nuxt-link-locale>
                                    <div class="w-25 h-100 d-none"><!-- Start: toggle icon -->
                                        <div class="d-flex flex-row justify-content-end align-items-center h-100">
                                            <div class="d-flex justify-content-center align-items-center p-1 toggle-icon-div"><i class="icon-menu p-1 toggle-category-icon" style="font-size: 26px;color: rgb(255,255,255);background: #000000;border-radius: 4px;" @click="showhomemenuoflayout=true"></i><i class="icon-close p-1 toggle-category-icon" style="font-size: 26px;color: rgb(255,255,255);background: #000000;border-radius: 4px;" @click="showhomemenuoflayout=false"></i></div>
                                        </div><!-- End: toggle icon --><!-- Start: hidden menu -->
                                        <div class="main-category-of-layout" style="background: #fc0000;">
                                            <div class="container px-0 position-relative">
                                                <div class="row g-0 g-0">
                                                    <div class="col-3">
                                                        <div style="background: #ffffff;border-radius: 4px;"><!-- Start: danh muc chinh -->
                                                            <div class="h-100 p-1 dm-bold-bx" style="border-radius: 12px;min-height: 400px;">
                                                                <div class="h-100" style="background: #ffffff;border-radius: 5px;"><!-- Start: item -->
                                                                    <div class="d-flex align-items-xxl-center p-1 pointer main-category-of-layout-item">
                                                                        <div class="p-1 pointer w-100"><nuxt-link-locale class="a-decoration-none" to="/danh-muc/combo-phan-mem" @click="showhomemenuoflayout=false">
                                                                                <div class="d-flex justify-content-between align-items-center w-100 pe-2 main-category-item">
                                                                                    <div class="d-flex align-items-center main-category-hv">
                                                                                        <div style="width: 25px;"><img class="img-fluid w-100"></div><span>Dael - Khuyến Mãi Hôm Nay</span>
                                                                                    </div><i class="fa fa-caret-down"></i>
                                                                                </div>
                                                                            </nuxt-link-locale><!-- Start: danh muc con hidden div -->
                                                                            <div class="sub-category-hidden-div-of-layout px-1 dm-light-bx" style="border-radius: 4px;">
                                                                                <div class="h-100 ps-3 py-2 position-relative dm-bold-bx" style="background: #ffffff;border-radius: 4px;"><!-- Start: list dm con -->
                                                                                    <div class="row g-0 w-100"><!-- Start: item -->
                                                                                        <div class="col-6 p-1 z-1"><nuxt-link-locale class="a-decoration-none text-hv-orange" @click="showhomemenuoflayout=false">
                                                                                                <h5>Heading</h5>
                                                                                            </nuxt-link-locale>
                                                                                            <div class="ps-3 pe-2 d-none">
                                                                                                <div class="text-decoration-none pb-2">
                                                                                                    <div class="p-1 mb-1 category-item-lv-3"><nuxt-link-locale class="a-decoration-none">
                                                                                                            <p style="margin-bottom: 0;">Paragraph</p>
                                                                                                        </nuxt-link-locale></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div><!-- End: item -->
                                                                                    </div><!-- End: list dm con -->
                                                                                    <div class="background-pic"><img class="img-fluid" style="border-radius: 4px;"></div>
                                                                                </div>
                                                                            </div><!-- End: danh muc con hidden div -->
                                                                        </div>
                                                                    </div><!-- End: item -->
                                                                </div>
                                                            </div><!-- End: danh muc chinh -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- End: hidden menu -->
                                    </div>
                                </div>
                            </div><!-- End: logo --><!-- Start: tìm kiếm (tk) -->
                            <div class="col-auto col-md-5 d-none d-lg-flex justify-content-center align-items-center justify-content-lg-center">
                                <div class="d-flex flex-column justify-content-xxl-center w-100 h-100 px-2 position-relative home-search pb-2">
                                    <div class="d-flex">
                                        <div class="text-white pointer p-1" v-for="(groupProductLv1,index) in (groupProductLv1s||[]).filter((itss,itx) => itx<5)" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="text-decoration-none text-white" :to="'/danh-muc/'+groupProductLv1.slug"><span class="m-1 one-line-truncate">{{groupProductLv1.name}}</span></nuxt-link-locale></div>
                                    </div><!-- Start: search-input -->
                                    <div class="input-container position-relative">
                                        <div class="input-group d-flex flex-row" id="search-input-1"><input class="form-control home-search-input-group fs-12 p-2" type="text" placeholder="Sale Giữa Tháng - Deal To Giá Nhỏ" v-model="keyword" @update:model-value="()=>{loadDatalistProductSearch()}"><input type="checkbox" class="checkbox-hack"><span class="input-group-text text-white pointer" @click="()=>{loadDatalistProductSearch()}"><i class="icon-magnifier px-2 fs-5"></i></span></div><!-- Start: hiển thị thông tin tìm kiếm của input -->
                                        <div class="suggestions-box" v-if="keyword!==''"><!-- Start: list product -->
                                            <div class="mt-3 mb-2"><!-- Start: item -->
                                                <div v-for="(listProductSearch,index) in listProductSearchs" v-bind:key="listProductSearch._id"><!-- Start: product -->
                                                    <div class="d-flex"><nuxt-link-locale class="text-decoration-none text-dark w-100" :to="'/san-pham/'+listProductSearch.slug">
                                                            <div class="d-flex p-1 hover-menu w-100">
                                                                <div class="search-img"><img class="img-fluid" :src="listProductSearch.image" :alt="listProductSearch.name"></div>
                                                                <div class="d-flex flex-column fs-14 p-1 search-desc">
                                                                    <p class="pm-0 tx-price">{{formatNumberMoney(listProductSearch.price)}}</p>
                                                                    <p class="pm-0">{{listProductSearch.name}}</p>
                                                                </div>
                                                            </div>
                                                        </nuxt-link-locale></div><!-- End: product -->
                                                </div><!-- End: item -->
                                            </div><!-- End: list product --><!-- Start: list brands -->
                                            <div class="pb-1">
                                                <h6 class="mb-0">Tìm theo thương hiệu</h6>
                                                <div class="p-1">
                                                    <div class="row g-0 d-flex g-2">
                                                        <div class="col-4"><nuxt-link-locale class="a-decoration-none">
                                                                <div class="search-brand"><img class="img-fluid w-100"></div>
                                                            </nuxt-link-locale></div>
                                                    </div>
                                                </div>
                                            </div><!-- End: list brands -->
                                        </div><!-- End: hiển thị thông tin tìm kiếm của input -->
                                    </div><!-- End: search-input -->
                                </div>
                            </div><!-- End: tìm kiếm (tk) -->
                            <div class="col-md-5 col-xxl-5 d-none d-lg-flex flex-row justify-content-end align-items-center">
                                <div class="d-flex justify-content-around w-100 h-100"><!-- Start: ngôn ngữ -->
                                    <div class="d-flex align-items-center" @click="showlanguage=!showlanguage"><!-- Start: Ngôn ngữ -->
                                        <div class="d-flex flex-column justify-content-end align-items-center me-2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇻🇳.png" alt="logo"></div>
                                                <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇨🇿.png" alt="logo"></div>
                                            </div>
                                            <div class="d-flex justify-content-center align-items-center txt-header-right pb-1"><span>Tiếng việt</span></div>
                                        </div><!-- End: Ngôn ngữ -->
                                    </div><!-- End: ngôn ngữ --><!-- Start: đang nhập -->
                                    <div class="d-flex align-items-center account-info h-100"><!-- Start: đăng nhập đăng ký -->
                                        <div class="d-flex ms-2"><!-- Start: tài khoản -->
                                            <div class="d-flex justify-content-center align-items-center pointer"><!-- Start: img -->
                                                <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_2.png"></div><!-- End: img --><!-- Start: tài khoản -->
                                                <div class="d-flex flex-column justify-content-start align-items-start txt-header-right ms-2 text-dark" v-if="userGetMe._id===undefined" @click="userGlobalState.isshowloginform=true"><span>Đăng nhập / Đăng ký</span><span>Tài khoản</span></div><!-- End: tài khoản --><!-- Start: tài khoản đã đăng nhập -->
                                                <div class="d-flex flex-column align-items-start txt-header-right ms-2 text-dark" v-if="userGetMe._id!==undefined"><span>Xin chào</span><span>{{userGetMe.fullName}}</span></div><!-- End: tài khoản đã đăng nhập --><!-- Start: sub-menu -->
                                                <div class="p-2 d-none" v-if="userGetMe._id!==undefined">
                                                    <div class="d-flex align-items-center"><i class="icon ion-document me-2"></i>
                                                        <p class="txt-header-right pm-0">Tài khoản của bạn</p>
                                                    </div>
                                                    <div class="d-flex align-items-center"><i class="icon ion-clipboard me-2"></i>
                                                        <p class="txt-header-right pm-0" style="margin: 0;">Quản lý đơn hàng</p>
                                                    </div>
                                                    <div class="d-flex align-items-center"><i class="icon ion-ios-heart-outline me-2"></i>
                                                        <p class="txt-header-right">Sản phẩm yêu thích</p>
                                                    </div>
                                                    <div class="d-flex align-items-center"><i class="icon ion-location me-2"></i>
                                                        <p class="txt-header-right">Địa chỉ giao hàng</p>
                                                    </div>
                                                    <div class="d-flex align-items-center"><i class="icon ion-android-exit me-2"></i>
                                                        <p class="txt-header-right pm-0" style="margin: 0;">Thoát</p>
                                                    </div>
                                                </div><!-- End: sub-menu -->
                                            </div><!-- End: tài khoản -->
                                        </div><!-- End: đăng nhập đăng ký --><!-- Start: menu con -->
                                        <div class="menu-account" v-if="userGetMe._id!==undefined">
                                            <div class="d-flex flex-column category"><!-- Start: tài khoản của bạn -->
                                                <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" to="/tai-khoan"><i class="icon-calendar me-1"></i><span>{{$t('taiKhoanCuaBan')}}</span></nuxt-link-locale></div><!-- End: tài khoản của bạn --><!-- Start: quản lý đơn hang -->
                                                <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" to="/tai-khoan/don-hang"><i class="icon-notebook me-1"></i><span>{{$t('quanLyDonHang')}}</span></nuxt-link-locale></div><!-- End: quản lý đơn hang --><!-- Start: Sản phẩm yêu thích -->
                                                <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" to="/tai-khoan/yeu-thich"><i class="icon-heart me-1"></i><span>{{$t('sanPhamYeuThich')}}</span></nuxt-link-locale></div><!-- End: Sản phẩm yêu thích --><!-- Start: Thoát -->
                                                <div class="fs-14 py-2 pointer px-2 hover-effect" @click="()=>{reload()}"><i class="icon-login me-1"></i><span>{{$t('thoat')}}</span></div><!-- End: Thoát -->
                                            </div>
                                        </div><!-- End: menu con -->
                                    </div><!-- End: đang nhập --><!-- Start: hỗ trợ -->
                                    <div class="d-flex align-items-center"><!-- Start: hỗ trợ -->
                                        <div class="d-flex align-items-center ms-2"><nuxt-link-locale class="text-decoration-none" to="/ho-tro">
                                                <div class="d-flex pointer">
                                                    <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_3.png"></div>
                                                    <div class="d-flex flex-column justify-content-center txt-header-right ms-2 text-dark"><span>Hỗ trợ&nbsp;</span><span>khách hàng</span></div>
                                                </div>
                                            </nuxt-link-locale></div><!-- End: hỗ trợ -->
                                    </div><!-- End: hỗ trợ --><!-- Start: giỏ hàng -->
                                    <div class="d-flex align-content-center"><!-- Start: giỏ hàng -->
                                        <div class="d-flex justify-content-around align-items-center pointer position-relative ms-2"><nuxt-link-locale to="/gio-hang">
                                                <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_1.png"></div>
                                                <div class="d-flex justify-content-center align-items-center position-absolute icon-cart"><span class="text-white">{{productCart?.length}}</span></div>
                                            </nuxt-link-locale></div><!-- End: giỏ hàng -->
                                    </div><!-- End: giỏ hàng -->
                                </div><!-- Start: none -->
                                <div class="d-flex justify-content-center align-items-center w-100 h-100 d-none">
                                    <div class="row g-0 w-100">
                                        <div class="col-lg-3">
                                            <div><!-- Start: Ngôn ngữ -->
                                                <div class="d-flex justify-content-end me-2">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇻🇳.png"></div>
                                                        <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇨🇿.png"></div>
                                                    </div>
                                                    <div class="d-flex justify-content-center align-items-center txt-header-right"><span>Ngôn ngữ</span></div>
                                                </div><!-- End: Ngôn ngữ -->
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div><!-- Start: đăng nhập đăng ký -->
                                                <div class="d-flex ms-2"><nuxt-link-locale class="text-decoration-none" to="#ddd">
                                                        <div class="d-flex justify-content-center align-items-center pointer"><!-- Start: img -->
                                                            <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_2.png"></div><!-- End: img --><!-- Start: tài khoản -->
                                                            <div class="d-flex flex-column justify-content-center align-items-center txt-header-right ms-2 text-dark"><span>Đăng nhập / Đăng ký</span><span>Tài khoản</span></div><!-- End: tài khoản --><!-- Start: sub-menu -->
                                                            <div class="p-2 d-none">
                                                                <div class="d-flex align-items-center"><i class="icon ion-document me-2"></i>
                                                                    <p class="txt-header-right pm-0">Tài khoản của bạn</p>
                                                                </div>
                                                                <div class="d-flex align-items-center"><i class="icon ion-clipboard me-2"></i>
                                                                    <p class="txt-header-right pm-0" style="margin: 0;">Quản lý đơn hàng</p>
                                                                </div>
                                                                <div class="d-flex align-items-center"><i class="icon ion-ios-heart-outline me-2"></i>
                                                                    <p class="txt-header-right">Sản phẩm yêu thích</p>
                                                                </div>
                                                                <div class="d-flex align-items-center"><i class="icon ion-location me-2"></i>
                                                                    <p class="txt-header-right">Địa chỉ giao hàng</p>
                                                                </div>
                                                                <div class="d-flex align-items-center"><i class="icon ion-android-exit me-2"></i>
                                                                    <p class="txt-header-right pm-0" style="margin: 0;">Thoát</p>
                                                                </div>
                                                            </div><!-- End: sub-menu -->
                                                        </div>
                                                    </nuxt-link-locale></div><!-- End: đăng nhập đăng ký -->
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div><!-- Start: hỗ trợ -->
                                                <div class="d-flex align-items-center ms-2"><nuxt-link-locale class="text-decoration-none" to="#ddd">
                                                        <div class="d-flex pointer">
                                                            <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_3.png"></div>
                                                            <div class="d-flex flex-column justify-content-center txt-header-right ms-2 text-dark"><span>Hỗ trợ&nbsp;</span><span>khách hàng</span></div>
                                                        </div>
                                                    </nuxt-link-locale></div><!-- End: hỗ trợ -->
                                            </div>
                                        </div>
                                        <div class="col-lg-1">
                                            <div><!-- Start: giỏ hàng -->
                                                <div class="d-flex justify-content-around align-items-center pointer position-relative ms-2"><nuxt-link-locale to="#ddd">
                                                        <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_1.png"></div>
                                                        <div class="d-flex justify-content-center align-items-center position-absolute icon-cart"><span class="text-white">0</span></div>
                                                    </nuxt-link-locale></div><!-- End: giỏ hàng -->
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- End: none -->
                            </div>
                        </div>
                    </div><!-- End: menu pc --><!-- Start: list danh mục -->
                    <div class="bg-home-menu mmm">
                        <div class="container position-relative">
                            <div class="d-flex pointer position-relative"><!-- Start: nav-2 -->
                                <div><!-- Start: danh mucj -->
                                    <div class="px-2 py-2 dm-div z-3"><i class="fa fa-align-justify pe-2"></i>
                                        <p class="mb-0 pm-0 danhmuc-text">DANH MỤC</p><!-- Start: menu-lv1 -->
                                        <div class="menu-lv1 w-100"><!-- Start: left -->
                                            <div class="left h-100 position-relative bg-white"><!-- Start: item -->
                                                <div class="item" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="a-decoration-none" :to="'/danh-muc/'+groupProductLv1.slug"><span class="d-flex justify-content-between align-items-center title-lv1"><span>{{groupProductLv1.name}}</span><i class="icon-arrow-down"></i></span></nuxt-link-locale><!-- Start: right -->
                                                    <div class="right">
                                                        <div class="w-100 h-100">
                                                            <div class="row g-0 d-flex h-100">
                                                                <div class="col-9"><!-- Start: gallery List-menu -->
                                                                    <div class="h-100 bg-white gallery"><!-- Start: gallery-item item-by-lv2 -->
                                                                        <div class="gallery-item" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id">
                                                                            <div class="mb-2" v-if="groupProductLv2.groupProduct?.includes(groupProductLv1._id)"><!-- Start: item-menu -->
                                                                                <div class="item-menu"><nuxt-link-locale class="a-decoration-none" :to="'/danh-muc/'+groupProductLv2.slug"><span class="title-lv-2">{{groupProductLv2.name}}</span></nuxt-link-locale><!-- Start: menu-lv-3 -->
                                                                                    <div class="menu-lv3 ps-2"><!-- Start: item-lv-3 -->
                                                                                        <div v-for="(groupProductLv3,index) in groupProductLv3s" v-bind:key="groupProductLv3._id">
                                                                                            <div v-if="groupProductLv3.groupProduct?.includes(groupProductLv2._id)"><nuxt-link-locale class="a-decoration-none" :to="'/danh-muc/'+groupProductLv3.slug"><span>{{groupProductLv3.name}}</span></nuxt-link-locale></div>
                                                                                        </div><!-- End: item-lv-3 -->
                                                                                    </div><!-- End: menu-lv-3 -->
                                                                                </div><!-- End: item-menu -->
                                                                            </div>
                                                                        </div><!-- End: gallery-item item-by-lv2 -->
                                                                    </div><!-- End: gallery List-menu -->
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="d-flex justify-content-end justify-content-xxl-start align-items-xxl-end h-100 menu-img-div"><!-- Start: image -->
                                                                        <div class="menu-img"><img :src="groupProductLv1.thumbnail"></div><!-- End: image -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!-- End: right -->
                                                </div><!-- End: item -->
                                            </div><!-- End: left -->
                                        </div><!-- End: menu-lv1 -->
                                    </div><!-- End: danh mucj -->
                                </div><!-- End: nav-2 -->
                                <div class="d-flex">
                                    <div class="d-flex justify-content-center align-items-center py-1 px-2 menu-category" v-for="(listHomeMenu,index) in (listHomeMenus||[]).filter((itss,itx) => itx<4)" v-bind:key="listHomeMenu._id"><!-- Start: hot deals --><nuxt-link-locale class="text-decoration-none text-uppercase" :to="listHomeMenu.link">
                                            <p class="pm-0">{{listHomeMenu.label}}</p>
                                        </nuxt-link-locale><!-- End: hot deals --></div>
                                </div>
                            </div>
                        </div>
                    </div><!-- End: list danh mục -->
                </header><!-- End: pc -->
            </div>
        </div><!-- Start: main -->
        <div class="main-body">
            <main>
                <slot/>
            </main>
            <section>
                <footer><!-- Start: slogan-footer -->
                    <div class="d-lg-block">
                        <div class="container"><!-- Start: slogan-pc -->
                            <div class="row g-0 br-bot-footer w-100 bg-white" v-if="$device.isDesktopOrTablet">
                                <div class="col-6">
                                    <div class="d-flex justify-content-around w-100 py-3">
                                        <div class="slogan-div" v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id"><nuxt-link-locale class="a-decoration-none" :to="'/ho-tro/'+webContentsVeHasaki.slug">
                                                <div class="px-1" v-if="webContentsVeHasaki.groupWebContent==='Thông tin và slogan'">
                                                    <div class="d-flex justify-content-center mb-2"><img class="img-fluid img-footer" alt="icon-slogan" :src="webContentsVeHasaki.thumbnail"></div>
                                                    <div class="text-center d-flex flex-column justify-content-center align-items-center text-green px-2"><span>{{webContentsVeHasaki.title}}</span></div>
                                                </div>
                                            </nuxt-link-locale></div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex justify-content-evenly align-items-center py-3 h-100">
                                        <div>
                                            <div class="d-flex justify-content-center align-items-center mb-2"><img class="img-common" src="/static/cosmeticscz/icons_hotline_kn.png" alt="logo"><span class="ms-2">KHIẾU NẠI, GÓP Ý</span></div>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div class="d-flex justify-content-center align-items-center bg-home boder-info"><a class="text-decoration-none text-dark" :href="'mailto:'+websiteConfig.email">
                                                        <p class="pm-0 text-white">{{websiteConfig.email}}</p>
                                                    </a></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="d-flex justify-content-center align-items-center mb-2"><img class="img-common" src="/static/cosmeticscz/icons_hotline_tv.png" alt="logo"><span class="ms-2">TƯ VẤN</span></div>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div class="d-flex justify-content-center align-items-center bg-home boder-info"><a class="text-decoration-none text-dark" :href="'tel:'+websiteConfig.soDienThoai">
                                                        <p class="pm-0 text-white">{{websiteConfig.soDienThoai}}</p>
                                                    </a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- End: slogan-pc --><!-- Start: slogan-mo -->
                            <div class="d-flex flex-row py-3 h-100 bg-white d-none" v-if="$device.isMobile">
                                <div class="col-4" v-for="(webContentsVeHasaki,index) in (webContentsVeHasakis||[]).filter((itss,itx) => itx<4&&itx>0)" v-bind:key="webContentsVeHasaki._id"><nuxt-link-locale class="a-decoration-none">
                                        <div class="d-flex justify-content-center mb-2" v-if="webContentsVeHasaki.groupWebContent==='Thông tin và slogan'"><img class="img-fluid img-footer" :alt="websiteConfig.tenThuongHieuSeo" :src="webContentsVeHasaki.thumbnail"></div>
                                    </nuxt-link-locale></div>
                            </div><!-- End: slogan-mo --><!-- Start: bạn đã đăng nhập chưa -->
                            <div></div><!-- End: bạn đã đăng nhập chưa -->
                            <div v-if="$device.isMobile"><!-- Start: list -->
                                <div><!-- Start: item -->
                                    <div></div><!-- End: item -->
                                </div><!-- End: list -->
                                <div class="d-flex justify-content-center"><img class="img-fluid" src="/static/cosmeticscz/2.png"></div>
                            </div>
                        </div>
                    </div><!-- End: slogan-footer --><!-- Start: block-top-footer -->
                    <div class="d-none d-lg-block mb-4">
                        <div class="container d-lg-flex">
                            <div class="d-lg-flex w-100 bg-home p-2"><!-- Start: left -->
                                <div class="col-lg-5"><!-- Start: web-contents -->
                                    <div class="row g-0 ms-3 my-2"><!-- Start: contens item hỗ trợ kh -->
                                        <div class="col-12 col-lg-6 p-2">
                                            <div class="d-flex flex-column h-100"><!-- Start: group title -->
                                                <h4 class="title-footer text-uppercase">Hỗ trợ khách hàng</h4><!-- End: group title --><!-- Start: list contents by group -->
                                                <div><!-- Start: item -->
                                                    <div v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id">
                                                        <div v-if="webContentsVeHasaki.groupWebContent==='Hỗ Trợ Khách Hàng'"><nuxt-link-locale class="text-decoration-none pointer" :to="'/ho-tro/'+webContentsVeHasaki.slug">
                                                                <div class="txt-link-footer"><span>{{webContentsVeHasaki.title}}</span></div>
                                                            </nuxt-link-locale></div>
                                                    </div><!-- End: item -->
                                                </div><!-- End: list contents by group -->
                                            </div>
                                        </div><!-- End: contens item hỗ trợ kh --><!-- Start: contens item về hasaki -->
                                        <div class="col-12 col-lg-6 p-2">
                                            <div class="d-flex flex-column h-100"><!-- Start: group title -->
                                                <h4 class="title-footer text-uppercase">Về chúng tôi</h4><!-- End: group title --><!-- Start: list contents by group -->
                                                <div><!-- Start: item -->
                                                    <div v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id">
                                                        <div v-if="webContentsVeHasaki.groupWebContent==='Về Chúng Tôi'"><nuxt-link-locale class="text-decoration-none pointer" :to="'/ho-tro/'+webContentsVeHasaki.slug">
                                                                <div class="txt-link-footer"><span>{{webContentsVeHasaki.title}}</span></div>
                                                            </nuxt-link-locale></div>
                                                    </div><!-- End: item -->
                                                </div><!-- End: list contents by group -->
                                            </div>
                                        </div><!-- End: contens item về hasaki -->
                                    </div><!-- End: web-contents -->
                                </div><!-- End: left -->
                                <div class="col-12 col-lg-3 d-flex flex-row p-3">
                                    <div class="d-flex flex-column h-100">
                                        <h4 class="title-footer text-uppercase">Hợp tác và liên kết</h4><a class="text-decoration-none text-white" :href="websiteConfig.fanpage"><span>Website</span></a><nuxt-link-locale class="text-decoration-none pointer txt-link-footer" to="#ddd"><span>Cẩm nang</span></nuxt-link-locale>
                                        <div class="my-2"><nuxt-link-locale class="pointer me-2" :to="websiteConfig.fanpage"><img src="/static/cosmeticscz/fb.png" alt="logo"></nuxt-link-locale><nuxt-link-locale class="pointer me-2" to="/"><img src="/static/cosmeticscz/icon_tiktok.png" alt="logo"></nuxt-link-locale><nuxt-link-locale class="pointer me-2" to="/"><img src="/static/cosmeticscz/instagram.png" alt="logo"></nuxt-link-locale></div>
                                        <div>
                                            <h4 class="title-footer text-uppercase">THANH TOÁN</h4><!-- Start: icon -->
                                            <div><img class="me-2" src="/static/cosmeticscz/mastercard.png" alt="logo"><img class="me-2" src="/static/cosmeticscz/visa.png" alt="logo"><img class="me-2" src="/static/cosmeticscz/atm.png" alt="logo"></div><!-- End: icon -->
                                        </div>
                                    </div>
                                </div><!-- Start: right -->
                                <div class="col-lg-4 p-2">
                                    <div class="p-3">
                                        <h4 class="text-uppercase title-space-bottom">Cập nhật thông tin khuyến mãi</h4>
                                        <div class="d-flex input-container"><input type="email" placeholder="Email của bạn" v-model="thongTinLienHe.email"><button class="btn btn-primary btn-seach" type="button" @click="()=>{let stepBreak=0;;if( thongTinLienHe.email===''&amp;&amp;(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Vui lòng nhập địa chỉ email',variant:'danger'}})};if( thongTinLienHe.email==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){createThongtinlienhe()}}">Đăng ký</button></div>
                                    </div>
                                </div><!-- End: right -->
                            </div>
                        </div>
                    </div><!-- End: block-top-footer --><!-- Start: footer-main -->
                    <div class="mb-3">
                        <div class="container">
                            <div class="row"><!-- Start: content -->
                                <div class="col-12 col-lg-8">
                                    <div><!-- Start: content -->
                                        <div v-if="websiteConfig.thongTinSoHuu!==''">
                                            <div>
                                                <div class="d-flex justify-content-center py-2" :class="{'seo-content-active':seeMore===true,'seo-content':seeMore!==true}"><span v-html="websiteConfig.thongTinSoHuu"></span></div>
                                            </div>
                                            <div class="d-flex flex-row justify-content-center">
                                                <div class="p-2 pointer btn-seemore" @click="seeMore=!seeMore">
                                                    <h5 class="pm-0 fs-14" v-if="seeMore===false">Xem thêm</h5>
                                                    <h5 class="pm-0 fs-14" v-if="seeMore===true">Thu nhỏ</h5>
                                                </div>
                                            </div>
                                        </div><!-- End: content -->
                                    </div>
                                </div><!-- End: content -->
                                <div class="col-lg-4 pb-4">
                                    <div class="h-100" v-if="$device.isDesktopOrTablet"><!-- Start: chứng nhận -->
                                        <div class="flex-column mb-3"><span class="pe-3">Được chứng nhận</span><img class="img-fluid img-certification" src="/static/cosmeticscz/2.png" alt="logo"></div><!-- End: chứng nhận --><!-- Start: top-tìm -kiếm -->
                                        <div class="d-sm-none d-lg-block"><span style="font-weight:500">TOP TÌM KIẾM</span>
                                            <div class="d-flex flex-wrap mt-2 pointer">
                                                <div class="mb-3" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+groupProductLv1.slug"><span class="me-2 py-1 px-2 top-seach-bottom">{{groupProductLv1.name}}</span></nuxt-link-locale></div>
                                            </div>
                                        </div><!-- End: top-tìm -kiếm -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- End: footer-main -->
                    <div :class="{'d-none':route.path==='/danh-muc'}" v-if="$device.isDesktop">
                        <div class="container">
                            <div class="mb-2 p-1"><span class="text-uppercase" style="font-weight:500">{{$t('danhMucSanPham')}}</span></div>
                            <div class="row d-flex"><!-- Start: cột 1 -->
                                <div class="col-lg-3 mb-3" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id">
                                    <div>
                                        <div class="mb-1 p-1"><span class="text-capitalize">{{groupProductLv1.name}}</span></div>
                                        <div class="d-flex flex-wrap">
                                            <div class="mt-2 pointer" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+groupProductLv2.slug"><span class="category-footer" v-if="groupProductLv1._id===groupProductLv2.groupProduct">{{groupProductLv2.name}}</span></nuxt-link-locale></div>
                                        </div>
                                    </div>
                                </div><!-- End: cột 1 -->
                            </div>
                        </div>
                    </div><!-- Start: mes -->
                    <div>
                        <div class="fixed-buttom py-2 img-logo-chat pointer"><a class="a-decoration-none" :href="websiteConfig.fanpage"><img class="img-fluid ani-zoom-in-blur" src="/static/cosmeticscz/messenger.png" alt="icon-mesenger"></a></div>
                    </div><!-- End: mes -->
                    <div class="d-flex justify-content-center align-items-center mt-3 mb-1 mb-lg-0">
                        <p class="py-2" style="margin-bottom: 0;">@2024 design by&nbsp;<a class="web-config-link" href="https://cuoiagency.com/">Cuội Agency</a></p>
                    </div>
                </footer>
            </section>
        </div><!-- End: main -->
    </section><client-only @click="showLogin=true"><b-modal role="dialog" tabindex="-1" id="modal-1" v-model="showlanguage" hide-footer hide-header centered hide-header-close>
            <div @click="showlanguage=false">
                <div class="d-flex flex-column justify-content-center align-items-center select-language p-2">
                    <p class="pm-0">Chọn ngôn ngữ</p>
                    <p class="pm-0">(Zvolte jazyk)</p>
                </div>
                <div><!-- Start: vn -->
                    <div class="d-flex justify-content-between align-items-center p-2 pointer hover-language">
                        <div><span>Tiếng Việt</span></div>
                        <div class="img-flag"><img class="img-fluid w-100" src="/static/cosmeticscz/🇻🇳.png"></div>
                    </div><!-- End: vn --><!-- Start: cz -->
                    <div class="d-flex justify-content-between align-items-center p-2 pointer hover-language">
                        <div><span>čeština</span></div>
                        <div class="img-flag"><img class="img-fluid w-100" src="/static/cosmeticscz/🇨🇿.png"></div>
                    </div><!-- End: cz -->
                </div>
            </div>
        </b-modal><!-- Start: filter --><b-offcanvas placement="start" id="offcanvas-2" class="w-100 bg-opacity h-100" v-model="showFilter">
            <div class="w-100 bg-opacity h-100">
                <div class="d-flex h-100 bg-opacity w-100">
                    <div class="w-75 h-100 bg-main"><!-- Start: lọc sản phẩm -->
                        <div class="d-flex justify-content-center">
                            <h5>Lọc sản phẩm</h5>
                        </div><!-- End: lọc sản phẩm -->
                        <div class="bg-white h-100">
                            <div class="overflow-auto h-75"><!-- Start: danh mục -->
                                <div class="p-2 box-filter mb-3"><!-- Start: title -->
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <h5>Danh mục</h5>
                                        <div @click="test=!test"><i class="icon ion-chevron-down" v-if="test===true"></i><i class="icon ion-chevron-down" v-if="test===false"></i></div>
                                    </div><!-- End: title -->
                                    <div class="mb-2 py-2" :class="{'test2':test===true,'test1':test===false}">
                                        <div class="d-flex flex-wrap"><span class="me-2 py-1 px-2 top-seach-bottom mb-2">Text</span></div>
                                    </div>
                                </div><!-- End: danh mục --><!-- Start: nhóm thuộc tính -->
                                <div><!-- Start: danh mục -->
                                    <div class="p-2 box-filter mb-3"><!-- Start: title -->
                                        <div class="d-flex justify-content-between align-items-center py-2">
                                            <h5>Danh mục</h5>
                                            <div @click="test=!test"><i class="icon ion-chevron-down" v-if="test===true"></i><i class="icon ion-chevron-down" v-if="test===false"></i></div>
                                        </div><!-- End: title -->
                                        <div class="mb-2 py-2" :class="{'test2':test===true,'test1':test===false}">
                                            <div class="d-flex flex-wrap"><span class="me-2 py-1 px-2 top-seach-bottom mb-2">Text</span></div>
                                        </div>
                                    </div><!-- End: danh mục -->
                                </div><!-- End: nhóm thuộc tính -->
                            </div><!-- Start: btn -->
                            <div class="d-flex justify-content-around"><button class="btn btn-primary px-4 btn-signup" type="button">Xóa tất cả</button><button class="btn btn-primary px-4 btn-signup-lg" type="button">Áp dụng</button></div><!-- End: btn -->
                        </div>
                    </div><!-- Start: opacity -->
                    <div class="w-25 h-100" @click="showFilter=!showFilter"><i class="fa fa-close fs-27 text-white mx-2"></i></div><!-- End: opacity -->
                </div>
            </div>
        </b-offcanvas><!-- End: filter --><!-- Start: show login register --><b-modal role="dialog" tabindex="-1" id="modal-2" v-model="userGlobalState.isshowloginform" hide-footer hide-header hide-header-close centered><!-- Start: register -->
            <div v-if="showLoginLogout==='register'"><!-- Start: title top -->
                <div class="\">
                    <div class="py-2">
                        <h5>{{$t('dangKyTaiKhoan')}}</h5>
                    </div>
                </div><!-- End: title top --><!-- Start: email -->
                <div class="py-2"><label class="form-label">{{$t('email')}}</label><input type="text" class="w-100 p-2" placeholder="Nhập email hoặc số điện thoại" v-model="register.email"></div><!-- End: email --><!-- Start: mật khẩu -->
                <div class="py-2"><label class="form-label">{{$t('matKhau')}}</label><input type="password" class="w-100 py-2" v-model="register.password"></div><!-- End: mật khẩu --><!-- Start: Họ tên -->
                <div class="py-2"><label class="form-label">{{$t('hoVaTen')}}</label><input type="text" class="w-100 p-2" placeholder="Họ và tên" v-model="register.fullName"></div><!-- End: Họ tên --><!-- Start: check -->
                <div class="py-2"><b-form-checkbox-group class="input-group"></b-form-checkbox-group></div><!-- End: check --><!-- Start: btn đăng ký -->
                <div class="mb-2 py-2"><button class="btn btn-primary w-100 btn-signup-lg" type="button" @click="()=>{let stepBreak=0;;if( register.email==='' || register.password==='' || register.fullName===''&amp;&amp;(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Vui lòng nhập đầy đủ thông tin',variant:'danger'}})};if( register.email==='' || register.password==='' || register.fullName==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){signupGraphRegister()}}">ĐĂNG KÝ</button></div><!-- End: btn đăng ký -->
                <div class="d-flex p-2"><!-- Start: bạn đã có tài khoản -->
                    <div class="d-flex align-items-center me-3"><span>{{$t('banDaCoTaiKhoan')}}</span><span class="ms-2 btn-signup pointer" @click="showLoginLogout='login'">Đăng nhập ngay</span></div><!-- End: bạn đã có tài khoản -->
                </div>
            </div><!-- End: register --><!-- Start: login -->
            <div v-if="showLoginLogout==='login'"><!-- Start: title top -->
                <div>
                    <div>
                        <h5>{{$t('nhapThongTinDangNhap')}}</h5>
                    </div>
                </div><!-- End: title top --><!-- Start: email -->
                <div class="py-2"><label class="form-label">{{$t('email')}}</label><input type="text" class="w-100 p-2" placeholder="Nhập email hoặc số điện thoại" v-model="user.email"></div><!-- End: email --><!-- Start: mật khẩu -->
                <div class="py-2"><label class="form-label">{{$t('matKhau')}}</label><input type="password" class="p-2 w-100" v-model="user.password"></div><!-- End: mật khẩu -->
                <div class="mb-2 py-2"><button class="btn btn-primary w-100 btn-signup-lg" type="button" @click="button99de770e79026fb6417c4d42c8dd3ae5d2664785click">{{$t('dangNhap')}}</button></div>
                <div class="d-flex p-2"><!-- Start: bạn đã có tài khoản -->
                    <div class="d-flex align-items-center me-3"><span>Bạn đã có tài khoản?</span><span class="ms-2 btn-signup pointer" @click="showLoginLogout='register'">Đăng ký ngay</span></div><!-- End: bạn đã có tài khoản -->
                </div>
            </div><!-- End: login -->
        </b-modal><!-- End: show login register --><!-- Start: menu --><b-offcanvas placement="start" id="offcanvas-3" class="w-100 bg-opacity h-100" v-model="showmenugoc" noheader header-class="true">
            <div class="w-100 bg-opacity h-100" style="padding: 0;">
                <div class="d-flex h-100 bg-opacity w-100">
                    <div class="w-75 bg-bdy h-100" @click="showmenugoc=false"><!-- Start: chưa đăng nhập -->
                        <div class="bg-home-menu py-3" @click="showLogin=true"><!-- Start: chưa đăng nhập -->
                            <div class="d-flex align-items-center" v-if="userGetMe._id===undefined"><!-- Start: icon -->
                                <div class="py-3 img-top-logo">
                                    <div class="img-icon"><img src="/static/cosmeticscz/icon_header_2.png"></div>
                                </div><!-- End: icon --><!-- Start: title -->
                                <div><span>{{$t('dangNhapDangKy')}}</span></div><!-- End: title -->
                            </div><!-- End: chưa đăng nhập --><!-- Start: đã đăng nhập -->
                            <div class="d-flex" v-if="userGetMe._id!==undefined"><!-- Start: icon -->
                                <div class="py-3 img-top-logo">
                                    <div class="img-icon"><img src="/static/cosmeticscz/icon_header_2.png"></div>
                                </div><!-- End: icon --><!-- Start: title -->
                                <div class="d-flex flex-column"><span>{{userGetMe.email}}</span><span>{{userGetMe.fullName}}</span></div><!-- End: title -->
                            </div><!-- End: đã đăng nhập -->
                        </div><!-- End: chưa đăng nhập --><!-- Start: bottom -->
                        <div class="mb-2 px-2 bg-white"><!-- Start: trang chủ -->
                            <div class="py-2 border-bottom"><!-- Start: trang chủ --><a class="text-decoration-none text-dark" href="/">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_1 (1).png"></div><span class="ms-2">{{$t('trangChu')}}</span>
                                    </div>
                                </a><!-- End: trang chủ --></div><!-- End: trang chủ --><!-- Start: danh mục -->
                            <div class="py-2 border-bottom"><!-- Start: danh mục sản phẩm --><a class="text-decoration-none text-dark" href="/danh-muc">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_2.png"></div><span class="ms-2">{{$t('danhMuc')}}</span>
                                    </div>
                                </a><!-- End: danh mục sản phẩm --></div><!-- End: danh mục --><!-- Start: giỏ hàng -->
                            <div class="py-2 border-bottom"><!-- Start: giỏ hàng --><a class="text-decoration-none text-dark" href="/gio-hang">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_3.png"></div><span class="ms-2">{{$t('gioHang')}}</span>
                                    </div>
                                </a><!-- End: giỏ hàng --></div><!-- End: giỏ hàng --><!-- Start: hot deals -->
                            <div class="py-2 border-bottom"><!-- Start: hot deals --><a class="text-decoration-none text-dark" href="/chien-dich">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/promotion.png"></div><span class="ms-2">{{$t('hotDeals')}}</span>
                                    </div>
                                </a><!-- End: hot deals --></div><!-- End: hot deals --><!-- Start: thương hiệu -->
                            <div class="py-2 border-bottom"><!-- Start: thương hiệu --><a class="text-decoration-none text-dark" href="/thuong-hieu">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_6.png"></div><span class="ms-2">{{$t('thuongHieu')}}</span>
                                    </div>
                                </a><!-- End: thương hiệu --></div><!-- End: thương hiệu --><!-- Start: hàng mới về -->
                            <div class="py-2 border-bottom"><!-- Start: hàng mới về --><a class="text-decoration-none text-dark" href="/hang-moi-ve">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_10.png"></div><span class="ms-2">{{$t('hangMoiVe')}}</span>
                                    </div>
                                </a><!-- End: hàng mới về --></div><!-- End: hàng mới về --><!-- Start: sản phẩm bán chạy -->
                            <div class="py-2 border-bottom"><!-- Start: hotline --><a class="text-decoration-none text-dark" href="/san-pham-ban-chay">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_1 (1).png"></div><span class="ms-2">Sản phẩm bán chạy</span>
                                    </div>
                                </a><!-- End: hotline --></div><!-- End: sản phẩm bán chạy --><!-- Start: Tri ân khách hàng -->
                            <div class="d-none py-2 border-bottom"><!-- Start: hotline --><a class="text-decoration-none text-dark" href="#">
                                    <div class="d-flex">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_1 (1).png"></div><span class="ms-2">Hotline</span>
                                    </div>
                                </a><!-- End: hotline --></div><!-- End: Tri ân khách hàng -->
                        </div><!-- End: bottom --><!-- Start: bottom -->
                        <div class="mb-2 px-2 bg-white"><!-- Start: kiểm tra đơn hàng -->
                            <div class="py-2 border-bottom"><!-- Start: Kiểm tra đơn hàng --><a class="text-decoration-none text-dark" href="/tra-cuu-don-hang">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_10.png"></div><span class="ms-2">{{$t('traCuuDonHang')}}</span>
                                    </div>
                                </a><!-- End: Kiểm tra đơn hàng --></div><!-- End: kiểm tra đơn hàng --><!-- Start: danh sách yêu thihcs -->
                            <div class="py-2 border-bottom"><!-- Start: danh sách yêu thihcs --><a class="text-decoration-none text-dark" href="/tai-khoan/yeu-thich">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_11.png"></div><span class="ms-2">{{$t('sanPhamYeuThich')}}</span>
                                    </div>
                                </a><!-- End: danh sách yêu thihcs --></div><!-- End: danh sách yêu thihcs --><!-- Start: quản lý tài khoản -->
                            <div class="py-2 border-bottom"><!-- Start: quản lý tài khoản --><a class="text-decoration-none text-dark" href="/tai-khoan">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_12.png"></div><span class="ms-2">{{$t('taiKhoanCuaBan')}}</span>
                                    </div>
                                </a><!-- End: quản lý tài khoản --></div><!-- End: quản lý tài khoản --><!-- Start: Tri ân khách hàng -->
                            <div class="py-2 d-none"><!-- Start: hotline --><a class="text-decoration-none text-dark" href="#">
                                    <div class="d-flex">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_1 (1).png"></div><span class="ms-2">Hotline</span>
                                    </div>
                                </a><!-- End: hotline --></div><!-- End: Tri ân khách hàng -->
                        </div><!-- End: bottom -->
                    </div>
                    <div class="w-25 h-100" @click="showmenugoc=!showmenugoc"><i class="icon ion-android-arrow-back fs-27 text-white mx-2"></i></div>
                </div>
            </div>
        </b-offcanvas><!-- End: menu --></client-only>
</div>
</template><script setup>
    import { computed,watch } from 'vue';



            const userGlobalState = useUserGlobalState();










    //replaceMome

    import {onMounted,} from 'vue';




    import {formatNumberMoney,} from '../util';


    const $device = useDevice();
    const { isMobile,isDesktop } = useDevice();
    const route = useRoute();



    const toast = useToast();

    const productCart = useCookie('productCart');
    productCart.value = productCart.value||[];


    const checkshowlanguage = useCookie('checkshowlanguage');
    checkshowlanguage.value = checkshowlanguage.value||false;





let user= ref({email: "",
    password: ""});

let register= ref({email: "",
    password: "",
    fullName: ""});
let showhomemenuoflayout= ref(false);
    let selectedId= ref("");
    let selectedLabel= ref("");
    let showmenu= ref("");
    let showlanguage= ref(false);
    let showmenugoc= ref(false);
    let idGroupLv1= ref("");
    let showFilter= ref(false);
    let signin= ref(false);
    let showLoginLogout= ref("login");
    let showLogin= ref(false);
    let seeMore= ref(false);
    let keyword= ref("");
    let key= ref("asdfg");
    let idgrouplv2= ref("");
    let test= ref(false);;
 let selectedMenu =ref({id: "",
    groupMenuLink: [],
label: "",
    selectedLinkLv2: ""});
let thongTinLienHe= ref({email: ""});










    //   variables
    if(process.env.NODE_ENV==='development'){

    }









    // computed


    //attributesCal 0




        // computed





        const clearTypeName = (api)=>{
            const funF = (obj)=>{
                if(obj){
                    console.log('obj')
                    Object.keys(obj).forEach((key)=>{
                        if(key==="__typename"){
                            delete obj.__typename;
                        } else if(Array.isArray(obj[key])){
                            obj[key].forEach((item)=>{
                                if(item)
                                    funF(item);
                            });
                        } else if(typeof (obj[key]) ==="object"&&(obj[key])){
                            if(obj[key])
                                funF(obj[key]);
                        }
                    });}
            };
            funF(api);
            return api;
        };

// mutation

        const createThongtinlienhe = async( )=>{
            try {

                   let ValthongTinLienHe = JSON.parse(JSON.stringify(thongTinLienHe.value))




                  ValthongTinLienHe = clearTypeName(ValthongTinLienHe);
                const {data} =  await useFetch('https://cosmeticscz.com/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation
 createContact($contact: contactCreateInput!){
        createContact(contact:$contact)
                {email _id }


}`,
                        variables: {contact:ValthongTinLienHe }}
                });

                if(data.value.data.createContact){
                    toast?.show({props:{title:'Thông báo',body:'Gửi thông tin thành công',variant:'success'}});thongTinLienHe.value.email=''
                }



                {

                }
                if(data.value.data.createContact){
                    data.value.data.createContact.value = JSON.parse(JSON.stringify(thongTinLienHe.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const signupGraphRegister = async( )=>{
            try {

                   let Valregister = JSON.parse(JSON.stringify(register.value))




                  Valregister = clearTypeName(Valregister);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation
 signupGraphUser($user: userSignupgraphInput!){
        signupGraphUser(user:$user)
                {email password fullName _id }


}`,
                        variables: {user:Valregister }}
                });

                if(data.value.data.signupGraphUser){
                    toast?.show({props:{title:'Thông báo',body:'Tạo tài khoản thành công',variant:'success'}});;register.value.email='';;register.value.fullName=''
                }



                {

                }
                if(data.value.data.signupGraphUser){
                    data.value.data.signupGraphUser.value = JSON.parse(JSON.stringify(register.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const loginGraphUser = async( )=>{
            try {

                   let Valuser = JSON.parse(JSON.stringify(user.value))




                  Valuser = clearTypeName(Valuser);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation
 loginGraphUser($user: userLogingraphInput!){
        loginGraphUser(user:$user)



}`,
                        variables: {user:Valuser }}
                });

                if(data.value.data.loginGraphUser){
                    toast?.show({props:{title:'Thông báo',body:'Đăng nhập thành công',variant:'success'}});;loadDatauserGetMe()
                }

                else{
                    toast?.show({props:{title:'Thông báo',body:'Thông tin đăng nhập không đúng',variant:'danger'}})
                }



                {

                }
                if(data.value.data.loginGraphUser){
                    data.value.data.loginGraphUser.value = JSON.parse(JSON.stringify(user.value))
                }

            } catch (e) {
                console.error(e)
            }

        }






    const loadDatalistProductSearch = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablelistProductSearchs={limit: 5, query :{}};
if( keyword.value!='' || keyword.value!==''){
                                    if(keyword.value&&keyword.value!==''){
                                    variablelistProductSearchs.query.name = {regex: keyword.value  }

                                    }

}if( keyword.value!='' || keyword.value!==''){if( keyword.value===null || keyword.value==='' || keyword.value===undefined){variablelistProductSearchs.query.name = {eq:"ádasdasdasdasd"}

}}
const resultlistProductSearchstemp = await useAsyncQuery( gql` query
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProductSearchs:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug image price
        }

}`,variablelistProductSearchs);
if(resultlistProductSearchstemp.data&&resultlistProductSearchstemp.data.value.listProductSearchs){
listProductSearchs.value = resultlistProductSearchstemp.data.value.listProductSearchs};
;

    };


    const loadDatauserGetMe = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variableme={};

const resultuserGetMetemp = await useAsyncQuery( gql` query {me
     {
                _id email fullName
        }
     }
`,variableme);
if(resultuserGetMetemp.data&&resultuserGetMetemp.data.value.me){
userGetMe.value = {...resultuserGetMetemp.data.value.me}};
;

    };

    const inputtext52578ebc4e1f4b316401b4c33393e7642ba3b3ccupdatemodelvalue = async function(){
        ;
    };
    const button99de770e79026fb6417c4d42c8dd3ae5d2664785click = async function(){
        let stepBreak=0;;if( user.value.email==='' || user.value.password===''&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Email hoặc mật khẩu không đúng',variant:'danger'}})};if( user.value.email==='' || user.value.password==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){await loginGraphUser()};if( (!stepBreak||stepBreak===4) ){user.value.email=''};if( (!stepBreak||stepBreak===5) ){};
    };
    const body48790a25a133bd5d540017fe10dc5506213c74b3created = async function(){
        await loadDatauserGetMe();userGlobalState.value.userId=userGetMe.value?._id;
    };






        //2 setup


        let userGetMe= ref({});
let groupProductLv1s= ref([]);
let groupProductLv2s= ref([]);
let groupProductLv3s= ref([]);
let listProductSearchs= ref([]);
let searchProductBrandss= ref([]);
let webContentsVeHasakis= ref([]);
let websiteConfig= ref({});
let listHomeMenus= ref([]);

        const slug=route.params['default-1'];

let variableuserGetMe={};

const resultuserGetMe = await useAsyncQuery(gql` query {me
     {
                _id email fullName
        }
     }
`,variableuserGetMe);
if(resultuserGetMe.data.value&&resultuserGetMe.data.value.me){
userGetMe.value = {...resultuserGetMe.data.value.me}}

let variablegroupProductLv1s={query :{groupProduct:{eq:null},highlight:{eq:true},status:{eq:true},}};

const resultgroupProductLv1s = await useAsyncQuery(gql` query
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv1s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug groupProduct highlight status thumbnail
        }

}`,variablegroupProductLv1s);
if(resultgroupProductLv1s.data.value&&resultgroupProductLv1s.data.value.groupProductLv1s){
groupProductLv1s.value = resultgroupProductLv1s.data.value.groupProductLv1s}

let variablegroupProductLv2s={query :{highlight:{eq:true},status:{eq:true},}};

const resultgroupProductLv2s = await useAsyncQuery(gql` query
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv2s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                name slug _id highlight status groupProduct
        }

}`,variablegroupProductLv2s);
if(resultgroupProductLv2s.data.value&&resultgroupProductLv2s.data.value.groupProductLv2s){
groupProductLv2s.value = resultgroupProductLv2s.data.value.groupProductLv2s}

let variablegroupProductLv3s={query :{status:{eq:true},}};

const resultgroupProductLv3s = await useAsyncQuery(gql` query
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv3s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug status groupProduct
        }

}`,variablegroupProductLv3s);
if(resultgroupProductLv3s.data.value&&resultgroupProductLv3s.data.value.groupProductLv3s){
groupProductLv3s.value = resultgroupProductLv3s.data.value.groupProductLv3s}
if(keyword.value!='' || keyword.value!==''){

let variablelistProductSearchs={limit: 5, query :{}};
if( keyword.value!='' || keyword.value!==''){
                                    if(keyword.value&&keyword.value!==''){
                                    variablelistProductSearchs.query.name = {regex: keyword.value  }

                                    }

}if( keyword.value!='' || keyword.value!==''){if( keyword.value===null || keyword.value==='' || keyword.value===undefined){variablelistProductSearchs.query.name = {eq:"ádasdasdasdasd"}

}}
const resultlistProductSearchs = await useAsyncQuery(gql` query
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProductSearchs:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug image price
        }

}`,variablelistProductSearchs);
if(resultlistProductSearchs.data.value&&resultlistProductSearchs.data.value.listProductSearchs){
listProductSearchs.value = resultlistProductSearchs.data.value.listProductSearchs}
}
let variablesearchProductBrandss={query :{status:{eq:true},}};

                                    if(keyword.value&&keyword.value!==''){
                                    variablesearchProductBrandss.query.name = {regex: keyword.value  }

                                    }

if( keyword.value===undefined || keyword.value==='' || keyword.value===null){variablesearchProductBrandss.query.name = {eq:"kjhkjhkjhkdjhfkdhg"}

}
const resultsearchProductBrandss = await useAsyncQuery(gql` query
 productBrandss($query: productBrandsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     searchProductBrandss:  productBrandss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug banner status
        }

}`,variablesearchProductBrandss);
if(resultsearchProductBrandss.data.value&&resultsearchProductBrandss.data.value.searchProductBrandss){
searchProductBrandss.value = resultsearchProductBrandss.data.value.searchProductBrandss}

let variablewebContentsVeHasakis={sort :{orderNumber:1}};

const resultwebContentsVeHasakis = await useAsyncQuery(gql` query
 webContentss($query: webContentsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     webContentsVeHasakis:  webContentss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id title slug groupWebContent thumbnail
        }

}`,variablewebContentsVeHasakis);
if(resultwebContentsVeHasakis.data.value&&resultwebContentsVeHasakis.data.value.webContentsVeHasakis){
webContentsVeHasakis.value = resultwebContentsVeHasakis.data.value.webContentsVeHasakis}

let variablewebsiteConfig={};

const resultwebsiteConfig = await useAsyncQuery(gql` query {  websiteConfig {
                _id logo fanpage tenMien thongTinSoHuu soDienThoai email iconicLogo tenThuongHieuSeo
        } }`,variablewebsiteConfig);
if(resultwebsiteConfig.data.value&&resultwebsiteConfig.data.value.websiteConfig){
websiteConfig.value = {...resultwebsiteConfig.data.value.websiteConfig}}

let variablelistHomeMenus={sort :{orderNumber:1}};

const resultlistHomeMenus = await useAsyncQuery(gql` query
 homeMenus($query: homeMenuFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listHomeMenus:  homeMenus(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id label link
        }

}`,variablelistHomeMenus);
if(resultlistHomeMenus.data.value&&resultlistHomeMenus.data.value.listHomeMenus){
listHomeMenus.value = resultlistHomeMenus.data.value.listHomeMenus}








        //2 afterData











        const methods=()=>{

        }
        const reload=()=>{
            setTimeout(()=>{
window.location.reload();
},1000
)

        }


        body48790a25a133bd5d540017fe10dc5506213c74b3created()
          onMounted(()=>{
            if( checkshowlanguage.value===false ){showlanguage.value=true};if( checkshowlanguage.value===false ){checkshowlanguage.value=true}
        })





  // watch















    //meta


        const meta = [  {name: "theme-color" ,content:"#fff"}];
        let title = '';

    title =''


         title= websiteConfig.value.seoTitle;
            meta.push({ name: 'title', content:websiteConfig.value.seoTitle });

            meta.push({ name: 'og:image', content:websiteConfig.value.logo });

            meta.push({ name: 'description', content:websiteConfig.value.seoDescription });

            meta.push({ name: 'og:url', content:websiteConfig.value.tenMien });

            meta.push({ name: 'keywords', content:websiteConfig.value.seoKeywords });



   const bodyAttrs ={};
    const  htmlAttrs={};




        useHead({
            title: title,
            viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
            charset: 'utf-8',
            meta: meta,
            htmlAttrs:htmlAttrs,
            bodyAttrs:bodyAttrs,
            script: [

            ],

        })



</script>


<style  >
    @import "assets/css/apieu.css";
@import "assets/css/ca-nhan.css";
@import "assets/css/cart.css";
@import "assets/css/cosmetic-two.css";
@import "assets/css/dienmay.css";
@import "assets/css/halt-june.css";
@import "assets/css/index.css";
@import "assets/css/layouts.css";
@import "assets/css/mau-u-dai.css";
@import "assets/css/Nav.css";
@import "assets/css/san-pham.css";
@import "assets/css/tapchi.css";
@import "assets/css/version-2.css";

</style>

<!--true-->
<script>



    export default {
        name: "default-1",



        data(){
            return {

                }
            },
                methods:{

                runAnimation(name,delay=0,loop=false,reverse=false){


                }



            }

        }
</script>

//update in Mon Jun 24 2024 10:03:28 GMT+0700 (Indochina Time) by loidirieng
